<template>
    <div class="row">
        <div class="col-12 col-md-4 mb-3">
            <qrcode-vue :value="address" size="200" level="M"/>
        </div>
        <div class="mb-3 col-12 col-md-8">
            <h3 class="mb-3">
                Wallet address
            </h3>
            <p class="mb-3">
                Scan the QR or copy this address to deposit
            </p>
            <Copy :address="address" />
        </div>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
export default {
    props: ['address'],
    components: {
        QrcodeVue
    }
}
</script>