<template>
  <div>
    <div v-if="loading == false">
      <div class="row text-center">
        <div
          class="col-sm-4 p-3 click"
          v-for="asset in assets"
          :key="asset.id"
          @click="selectAsset(asset)"
        >
          <img
            class="w-50"
            :class="form.currency == asset.currency ? 'active' : ''"
            :src="asset.image"
            :alt="asset.name"
          />
        </div>
      </div>
    </div>
    <Loading v-else />
    <div class="text-right mt-3">
      <b-button
        variant="secondary"
        size="lg"
        class="mr-3"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <ButtonAction
        lg="true"
        :loading="loading"
        loadingTx="Creating wallet"
        title="Create wallet"
        @click="onSubmit"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      form: {
        currency: null,
        currency_id: null,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions("wallet", ["getAssets", "storeWallet"]),
    getData() {
      this.loading = true;
      this.getAssets().then(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.loading = true;
      this.storeWallet(this.form).then(() => {
        openNotification();
        this.loading = false;
        this.$emit("close");
      });
    },
    selectAsset(asset) {
      this.form.currency = asset.currency;
      this.form.currency_id = asset.id;
    },
  },
  computed: {
    ...mapState("wallet", ["assets"]),
  },
};
</script>
<style scoped>
.active {
  border: 5px solid #e3c87a;
  border-radius: 20px;
  transition: 0.2s all;
}
</style>
