<template>
  <div>
    <div class="text-right mb-3">
      <b-button variant="primary" size="lg" @click="modalCreate = true">
        Create Wallet
      </b-button>
    </div>
    <Carousel :itemsToShow="3" :wrapAround="true">
      <Slide v-for="wallet in wallets" :key="wallet.id">
        <div class="carousel__item">
          <CardWallet
            :isActive="cardActive.id"
            :wallet="wallet"
            @click="cardActive = wallet"
          />
        </div>
      </Slide>
    </Carousel>

    <div class="row mt-4">
      <div class="col-4 text-left">
        <h3 class="title">Movements</h3>
      </div>
      <div class="col-8 text-right" v-if="cardActive.address">
        <b-button
          variant="outline-danger"
          class="mr-3"
          @click="modalDelete = true"
        >
          Delete
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-3"
          @click="modelDeposit = true"
        >
          Deposit
        </b-button>
        <b-button variant="outline-primary" @click="modelWithdraw =  true">
          Withdraw
        </b-button>
      </div>
    </div>

    <div v-if="cardActive.id != null">
      <Transactions
        class="mt-5"
        :wallet_id="cardActive.id"
        :key="cardActive.id"
      />
    </div>
    <b-modal
      size="lg"
      title="Crear wallet"
      centered
      hide-footer
      v-model="modalCreate"
    >
      <Create @close="reloadData" />
    </b-modal>
    <b-modal
      size="lg"
      centered
      title="Wallet deposit"
      hide-footer
      v-model="modelDeposit"
    >
      <Deposit :address="cardActive.address" />
    </b-modal>
    <b-modal
      size="lg"
      centered
      title="Withdraw"
      hide-footer
      v-model="modelWithdraw"
    >
      <Withdraw :wallet="cardActive" />
    </b-modal>
    <b-modal centered title="Delete wallet" hide-footer v-model="modalDelete">
      <h3>Are you sure you want delete this wallet?</h3>
      <p>This action cannot be reversed</p>
      <div class="text-right">
        <b-button class="mr-3" @click="modalDelete = false"> Cancel </b-button>
        <b-button variant="primary" @click="confirmDelete">
          Yes, delete
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import Transactions from './Transactions.vue'
import CardWallet from './CardWallet.vue'
import Create from './actions/Create.vue'
import Deposit from './actions/Deposit.vue'
import Withdraw from './actions/Withdraw.vue'

import 'vue3-carousel/dist/carousel.css';
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination,
    Create,
    CardWallet,
    Transactions,
    Deposit,
    Withdraw
  },
  data () {
        return {
            loading: false,
            modalCreate: false,
            modelDeposit: false,
            modelWithdraw: false,
            modalDelete: false,
            cardActive: {
                id: null
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('wallet', ['getWallets', 'destroyWallet']),
        getData () {
            this.loading = true
            this.getWallets().then(() => {
                this.loading = false
            })
        },
        reloadData() {
            this.modalCreate = false
            this.getData()
        },
        confirmDelete() {
            this.loading = true
            this.destroyWallet(this.cardActive.id).then(() => {
                this.getData()
                this.loading = false
                this.modalDelete = false
            })
        }
    },
    computed: {
        ...mapState('wallet', ['wallets'])
    }
});
</script>

<style scoped>
.carousel__slide{
    padding: 10px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>
