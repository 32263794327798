<template>
    <div>
        <div class="row bg-white">
            <div class="col-12 col-md-6 mb-3">
                <div>
                    <span>Destinantion wallet</span>
                    <b-form-input v-model="form.to" placeholder="Enter a destination wallet" />
                </div>
                <div class="mt-3">
                    <span>Amount to transfer</span>
                    <b-form-input v-model="form.quantity" placeholder="Amount to transfer" />
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-6">
                            <h5>
                                Fee: 2%
                            </h5>
                        </div>
                        <div class="col-6" style="font-weight: 500">
                            <h5>
                                Receive: {{ form.quantity - (form.quantity * (2 / 100)) }} {{ wallet.currency }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <CardWallet :isActive="wallet.id" :wallet="wallet" />
            </div>
            <div class="col-6 mt-4">
                <b-button variant="link" class="w-100 btn-lg" @click="$emit('close')">
                    <span style="color:black">Cancel</span>
                </b-button>
            </div>
            <div class="col-6 mt-4">
                <ButtonAction lg="true" block="true" :loading="loading" loadingTx="Sending..." title="Confirm transaction" @click="onSubmit"/>
            </div>
        </div>
        <b-modal size="lg" centered hide-footer title="Transaction hash" v-model="showHash">
            <h3>Transaction hash</h3>
            <Copy colored="true" :address="txHash" class="mt-3"/>
            <div class="text-right mt-3">
                <b-button variant="primary" class="btn-lg" @click="showHash = false">
                    Okay, close it
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import CardWallet from '../CardWallet.vue'
export default {
    props: {
        wallet: {
            required: true
        }
    },
    components: {
        CardWallet
    },
    data() {
        return {
            loading: false,
            showHash: false,
            txHash: false,
            form: {
                to: null,
                quantity: null,
                wallet_id: null
            }
        }
    },
    created () {
        this.form.wallet_id = this.wallet.id
    },
    methods: {
        ...mapActions('wallet', ['sendTransaction']),
        onSubmit () {
            this.loading = true
            this.sendTransaction(this.form).then(response => {
                this.loading = false
                this.txHash = response
                this.$emit('close')
                this.showHash = true
            })
        }
    }
}
</script>